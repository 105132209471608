import i18n from '@/common/config/i18n'
import { array, number, object, string } from 'yup'
import { checkinQuestionSchema } from './checkinQuestionSchema'

const { t } = i18n

export const requestVisitFormSchema = object({
  date: object({
    day: number().required(),
    month: number().required(),
    year: number().required()
  }).required(t('requiredField')),
  checkIn: string().required(t('requiredField')),
  checkOut: string().required(t('requiredField')),
  hosts: array(string().required())
    .required()
    .min(1, t('selectAtLeastOneHost')),
  visitQuestionAnswers: array(checkinQuestionSchema).required()
})
