import { client } from '@/common/config'
import { type components } from '@/common/models'

type Params = components['schemas']['RequestContractorPTWExtensionRequestDto']

export const requestPermitExtension = async (params: Params) => {
  const { error } = await client.POST('/api/app/permit-to-work/extension', {
    body: params
  })

  if (error !== undefined) {
    console.error('Error requesting permit extension:', error)
    return await Promise.reject(error)
  }
}
