// External
import { Platform } from 'react-native'
// Config
import { client } from '@/common/config'
// Models
import type { Asset } from '@/common/models'
// Utils
import { getAndroidFileType, getWebFile } from '@/common/utils'

interface Params {
  id: string
  value: Asset[]
}

export const uploadCustomFieldAttachments = async ({ id, value }: Params) => {
  try {
    let attachments: any

    if (Platform.OS === 'web') {
      const promises = value.map(
        async (attachment, index) =>
          await getWebFile({
            asset: attachment,
            fallbackName: `Attachment ${index + 1}`
          })
      )
      attachments = await Promise.all(promises)
    } else {
      attachments = value.map((attachment, index) => {
        const isDocumentPickerAsset = 'name' in attachment

        return {
          name: isDocumentPickerAsset
            ? attachment.name.split('.')[0]
            : attachment.fileName ?? `Attachment ${index + 1}`,
          type:
            Platform.OS === 'android'
              ? getAndroidFileType(attachment)
              : isDocumentPickerAsset
              ? attachment.mimeType
              : attachment.type,
          uri:
            Platform.OS === 'ios'
              ? attachment.uri.replace('file://', '')
              : attachment.uri
        }
      })
    }

    const body = new FormData()

    // @ts-expect-error - attachment implicitly has an 'any' type
    attachments.forEach((attachment) => {
      body.append('attachments', attachment)
    })

    const { error } = await client.POST(
      '/api/app-user/custom-fields/{customFieldId}/upload',
      {
        params: {
          path: {
            customFieldId: id
          }
        },
        // @ts-expect-error - body doesn't match OpenAPI's generated type
        body,
        // ! will not work if this line is removed
        bodySerializer: (body) => body
      }
    )

    if (error !== undefined) {
      console.error('Error uploading custom field attachments:', error)
      return await Promise.reject(error)
    }
  } catch (error) {
    console.error('Error uploading custom field attachments:', error)
    return await Promise.reject(error)
  }
}
