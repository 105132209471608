import i18n from '@/common/config/i18n'
import { VisitCheckinQuestionTypes } from '@/common/models'
import { boolean, mixed, object, string } from 'yup'

const { t } = i18n

export const checkinQuestionSchema = object({
  questionId: string().required(),
  optional: boolean().required(),
  answer: string().when(['optional', 'type'], {
    is: (optional: boolean, questionType: VisitCheckinQuestionTypes) =>
      optional || questionType === VisitCheckinQuestionTypes.ATTACHMENT,
    then: (schema) => schema.optional(),
    otherwise: (schema) => schema.required(t('requiredField'))
  }),
  type: string().oneOf(Object.values(VisitCheckinQuestionTypes)),
  attachment: mixed().when(['optional', 'type'], {
    is: (optional: boolean, questionType: VisitCheckinQuestionTypes) =>
      !optional && questionType === VisitCheckinQuestionTypes.ATTACHMENT,
    then: (schema) => schema.required(t('requiredField')),
    otherwise: (schema) => schema.optional()
  })
})
