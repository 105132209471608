// External
import { Input } from '@rneui/themed'
import { type ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import {
  type ViewStyle,
  Platform,
  TouchableOpacity as RNTouchableOpacity
} from 'react-native'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
// Components
import { Calendar } from './Calendar'
import { Icon } from './Icon'
// Constants
import { colors } from '@/common/constants'
// Utils
import { formatDate } from '@/common/utils'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

interface Props {
  style?: ViewStyle
  label?: string
  value?: Date
  minDate?: string
  onDayPress: (data: { year: number; month: number; day: number }) => void
  onSubmitEditing?: () => void
  placeholder?: string
  searchPlaceholder?: string
  errorMessage?: string
  disabled?: boolean
}

export const DateInput = forwardRef(
  (
    {
      style,
      label,
      value,
      minDate,
      onDayPress,
      onSubmitEditing,
      placeholder,
      errorMessage,
      disabled = false
    }: Props,
    ref: ForwardedRef<unknown>
  ) => {
    const { showModal, hideModal } = usePortalStore((state) => ({
      showModal: state.showModal,
      hideModal: state.hideModal
    }))

    const openCalendar = () => {
      showModal({
        content: (
          <Calendar
            {...(minDate !== undefined && {
              minDate: new Date(minDate)
            })}
            onDayPress={(data) => {
              onDayPress(data)
              hideModal()
              if (onSubmitEditing !== undefined) {
                setTimeout(() => {
                  onSubmitEditing()
                }, 100)
              }
            }}
          />
        )
      })
    }

    useImperativeHandle(ref, () => ({
      openCalendar
    }))

    const getValue = () => {
      if (value !== undefined) {
        return formatDate(value, 'P')
      }

      return value
    }

    return (
      <Wrapper style={style} disabled={disabled} onPress={openCalendar}>
        <Input
          label={label}
          inputContainerStyle={{
            borderColor:
              errorMessage !== undefined ? colors.error : 'transparent'
          }}
          disabled={disabled}
          editable={false}
          value={getValue()}
          placeholder={placeholder}
          rightIcon={
            <Icon
              style={{
                opacity: disabled ? 0.33 : 1
              }}
              name="calendar"
              size={24}
            />
          }
          errorMessage={errorMessage}
        />
      </Wrapper>
    )
  }
)
