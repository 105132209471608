import { updateVisit } from '@/visit/services'
import { uploadVisitQuestionAttachment } from '@/visit/services/uploadVisitQuestionAttachment'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'

interface Params {
  visitId: string
  visitUUID: string
  visitQuestionAnswers: Array<{
    questionId: string
    answer: string
  }>
  attachments: Array<{
    visitCheckinQuestionId: string
    attachment: ImagePickerAsset | DocumentPickerAsset
  }>
}

export const editVisit = async ({
  visitId,
  visitUUID,
  visitQuestionAnswers,
  attachments
}: Params) => {
  try {
    await updateVisit({
      visitUUID,
      visitQuestionAnswers
    })

    const promises = attachments.map(async (attachment) => {
      await uploadVisitQuestionAttachment({
        visitId,
        questionId: attachment.visitCheckinQuestionId,
        attachment: attachment.attachment
      })
    })

    await Promise.all(promises)
  } catch (error) {
    return await Promise.reject(error)
  }
}
