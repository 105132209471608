// External
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Text } from '@/common/components'
// Models
import type { components } from '@/common/models'

interface Props {
  area: components['schemas']['PTWRequestDetailResponse']['area']
}

export const PermitArea = ({ area }: Props) => {
  const { t } = useTranslation()

  return (
    <View>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('area')}
      </Text>

      <Text variant="small">{`• ${area.name}`}</Text>
    </View>
  )
}
