import i18n from '@/common/config/i18n'
import type { Asset } from '@/common/models'
import { Platform } from 'react-native'

export const getAttachmentName = ({
  attachment,
  index
}: {
  attachment: Asset
  index: number
}) => {
  const { t } = i18n

  if ('name' in attachment && attachment.name.length > 0) {
    return attachment.name
  }

  if ('fileName' in attachment && attachment.fileName !== null) {
    return attachment.fileName
  }

  if ('uri' in attachment) {
    if (Platform.OS === 'web') {
      return t('attachment', { index: index + 1 })
    }

    return attachment.uri.split('/').pop()
  }
}
