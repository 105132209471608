import { client } from '@/common/config'

export const getExtensions = async (permitRequestUUID: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/extension/{permitRequestUUID}',
    {
      params: {
        path: {
          permitRequestUUID
        },
        query: {
          page: 1,
          limit: 100
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting extensions:', error)
    return await Promise.reject(error)
  }

  return data.results
}
