import { client } from '@/common/config'

interface Params {
  ptwRequestUUID: string
  attachmentUUID: string
}

export const deletePermitRequestAttachment = async ({
  ptwRequestUUID,
  attachmentUUID
}: Params) => {
  console.log({ ptwRequestUUID, attachmentUUID })
  const { error } = await client.DELETE(
    '/api/app/permit-to-work/attachments/{ptwRequestUUID}/{attachmentUUID}',
    {
      params: {
        path: {
          ptwRequestUUID,
          attachmentUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error deleting permit request attachment:', error)
    return await Promise.reject(error)
  }
}
