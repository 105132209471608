import type { PTWStackParamList } from '@/permitToWork/models'
import PermitDetail from '@/permitToWork/views/PermitDetail'
import PermitToWork from '@/permitToWork/views/PermitToWork'
import RequestAnExtension from '@/permitToWork/views/RequestAnExtension'
import { createStackNavigator } from '@react-navigation/stack'

const Stack = createStackNavigator<PTWStackParamList>()

const PermitToWorkStack = () => {
  return (
    <Stack.Navigator
      initialRouteName="PermitToWork"
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen name="PermitToWork" component={PermitToWork} />

      <Stack.Screen name="PermitDetail" component={PermitDetail} />

      <Stack.Screen name="RequestAnExtension" component={RequestAnExtension} />
    </Stack.Navigator>
  )
}

export default PermitToWorkStack
