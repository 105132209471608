import type { components } from '@/common/models'
import { PTWExtensionRequestStatus } from '@/common/models'
import {
  getExtensions,
  getPermit,
  getPermitClosure,
  getPermitComments,
  getPermitSignatures
} from '@/permitToWork/services'

export const getPermitDetail = async (uuid: string) => {
  try {
    const [detail, signatures, comments, extensions, closure] =
      await Promise.all([
        getPermit(uuid),
        getPermitSignatures(uuid),
        getPermitComments(uuid),
        getExtensions(uuid),
        getPermitClosure(uuid)
      ])

    const pendingExtension = extensions.find(
      (extension) => extension.status === PTWExtensionRequestStatus.PENDING
    ) as components['schemas']['PTWExtensionRequestRowItemResponse'] | undefined

    return {
      detail,
      signatures,
      comments,
      extensions,
      pendingExtension,
      closure
    }
  } catch (error) {
    return await Promise.reject(error)
  }
}
