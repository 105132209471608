// External
import { type DrawerScreenProps } from '@react-navigation/drawer'
import { useFocusEffect } from '@react-navigation/native'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import QRCodeSVG from 'react-native-qrcode-svg'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import { EmailVerificationAlert } from '@/auth/components'
import { Avatar, Text, TopBar } from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { HomeDrawerParamList } from '@/home/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'

type Props = DrawerScreenProps<HomeDrawerParamList, 'QRCode'>

const QRCode = ({ navigation }: Props) => {
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const [qr, setQR] = useState<string>()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { left, right } = useSafeAreaInsets()

  useFocusEffect(
    useCallback(() => {
      if (
        user !== undefined &&
        currentUserType !== undefined &&
        currentFacility !== undefined
      ) {
        try {
          const qrData = JSON.stringify({
            appUserId: user._id,
            userTypeId: currentUserType._id,
            facilityId: currentFacility._id
          })
          setQR(qrData)
        } catch (error) {
          console.error('Error setting QR:', error)
          toast.error({
            data: {
              titleTranslationKey: 'error',
              messageTranslationKey: 'couldNotLoadQRCode'
            }
          })
          navigation.goBack()
        }
      }
    }, [user, currentUserType, currentFacility])
  )

  return (
    <SafeArea>
      <TopBar
        style={{ marginHorizontal: 25 }}
        title={t('qrCode')}
        leftIcon={{
          name: 'back',
          onPress: navigation.goBack
        }}
      />

      <EmailVerificationAlert />

      {user !== undefined &&
        currentUserType !== undefined &&
        currentFacility !== undefined &&
        qr !== undefined && (
          <View style={{ marginTop: 23, marginHorizontal: 25 }}>
            <View style={{ alignItems: 'center' }}>
              <Avatar
                style={{ marginBottom: 10 }}
                size={70}
                borderRadius={20}
                {...(user.profileImage !== undefined && {
                  source: { uri: user.profileImage }
                })}
                initials={user.firstName.charAt(0) + user.lastName.charAt(0)}
              />

              <Text variant="mediumBold">{`${user.firstName} ${user.lastName}`}</Text>

              <Text style={styles.userType}>
                {t(`userTypes.${currentUserType.slug}`)}
              </Text>

              <Text>{user.email}</Text>
            </View>

            <View
              style={[styles.qrContainer, { borderColor: colors.inactive }]}
            >
              <QRCodeSVG
                value={qr}
                color={colors.text}
                size={width - left - right - 100}
              />
            </View>

            <Text style={{ textAlign: 'center' }}>
              {t('showThisQR', { facilityName: currentFacility.name })}
            </Text>
          </View>
        )}
    </SafeArea>
  )
}
export default QRCode

const styles = StyleSheet.create({
  qrContainer: {
    marginVertical: 80,
    alignItems: 'center',
    paddingVertical: 15,
    justifyContent: 'center',
    borderRadius: 20,
    borderWidth: 4,
    width: 230,
    height: 230,
    alignSelf: 'center'
  },
  userType: {
    opacity: 0.5,
    marginVertical: 3,
    textTransform: 'capitalize'
  }
})
