// External
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
// Components
import { Avatar, Icon, Link, Text } from '@/common/components'
// Constants
import { colors } from '@/common/constants'
// Models
import { type components, PTWRequestStatus } from '@/common/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Services
import {
  addStaffOnSiteForRequest,
  getStaffOnSiteAvailableForRequest,
  removeStaffOnSiteForRequest
} from '@/permitToWork/services'

interface Props {
  permitUUID: string
  status: components['schemas']['PTWRequestDetailResponse']['status']
  staffOnSite: components['schemas']['PTWRequestDetailResponse']['staffOnSite']
  refetch?: () => void
}

export const PermitStaffOnSiteList = ({
  permitUUID,
  status,
  refetch,
  staffOnSite
}: Props) => {
  const { user } = useAppStore((state) => ({
    user: state.user
  }))
  const { t } = useTranslation()
  const enableEdit =
    status !== PTWRequestStatus.CLOSED && status !== PTWRequestStatus.REJECTED

  const addStaffOnSite = async (staffId: string) => {
    await addStaffOnSiteForRequest({
      uuid: permitUUID,
      body: { contractorIds: [staffId] }
    })
    refetch?.()
  }

  const removeStaff = async (staffId: string) => {
    await removeStaffOnSiteForRequest({
      uuid: permitUUID,
      body: { contractorIds: [staffId] }
    })
    refetch?.()
  }

  return (
    <View>
      <View style={styles.header}>
        <Text variant="label">{t('staffOnSite')}</Text>

        {enableEdit && (
          <Link
            style={styles.edit}
            onPress={async () => {
              const result = await SheetManager.show('infinite-select', {
                payload: {
                  searchable: true,
                  placeholder: t('selectStaffOnSite'),
                  searchPlaceholder: t('searchStaffOnSite'),
                  queryKey: ['staff-on-site-available', permitUUID],
                  queryFn: async ({ page, search }) =>
                    await getStaffOnSiteAvailableForRequest({
                      uuid: permitUUID,
                      page,
                      search
                    }),
                  getOptions: (data) =>
                    data.map((contractor) => {
                      return {
                        label: `${String(
                          contractor.appUser.firstName
                        )} ${String(contractor.appUser.lastName)}`,
                        value: contractor._id
                      }
                    }),
                  noResultsTranslationKey: 'noStaffOnSiteAvailable'
                }
              })
              await addStaffOnSite(result.value)
            }}
            color={colors.rajah}
            icon={{
              name: 'add-file'
            }}
          >
            {t('add')}
          </Link>
        )}
      </View>

      <View style={{ borderRadius: 7, backgroundColor: colors.inactive }}>
        {staffOnSite.map(
          (
            {
              _id,
              appUser: {
                _id: appUserId,
                fullName,
                firstName,
                lastName,
                email,
                profileImage
              }
            },
            i
          ) => (
            <View key={_id} style={styles.staff}>
              <Avatar
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                initials={`${firstName[0]}${lastName[0]}`}
                size={40}
                borderRadius={20}
                source={
                  profileImage != null ? { uri: profileImage } : undefined
                }
              />

              <View style={{ flex: 1 }}>
                <Text variant="smallBold" numberOfLines={1}>
                  {fullName}
                </Text>

                <Text
                  variant="small"
                  color={colors.text70Percent}
                  numberOfLines={1}
                >
                  {email}
                </Text>
              </View>

              {enableEdit && user?._id !== appUserId && (
                <Icon
                  name="trash"
                  size={20}
                  onPress={async () => {
                    await removeStaff(_id)
                  }}
                />
              )}
            </View>
          )
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  edit: {
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center'
  },
  staff: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: 15,
    padding: 12
  }
})
