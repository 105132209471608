import { client } from '@/common/config'
import type { PaginatedGetParams, VisitRequestStatus } from '@/common/models'

interface Params extends PaginatedGetParams {
  status: VisitRequestStatus
}

export const getVisitRequests = async ({
  status,
  page,
  limit = 10
}: Params) => {
  const { data, error } = await client.GET('/api/app/visit-requests', {
    params: {
      query: {
        status,
        page,
        limit
      }
    }
  })

  if (error !== undefined) {
    console.error('Error getting visits:', error)
    return await Promise.reject(error)
  }

  return data
}
