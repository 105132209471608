// External
import { FlashList } from '@shopify/flash-list'
import { useCallback } from 'react'
import { type ViewStyle, View } from 'react-native'
// Components
import { NoResults } from '@/common/components'
import { FacilityListItem } from './FacilityListItem'
import { FacilityListItemSkeleton } from './FacilityListItemSkeleton'
// Models
import type { components, Coordinates } from '@/common/models'
// Utils
import {
  calculateDistanceToFacility,
  sortFacilitiesByDistance
} from '@/findFacility/utils'

interface Props {
  contentContainerStyle?: ViewStyle
  isLoading: boolean
  facilities: Array<components['schemas']['FacilityListItemResponse']>
  currentPosition: Coordinates | undefined
  search: string
  onPressFacility: (
    facility: components['schemas']['FacilityListItemResponse']
  ) => void
}

export const FacilityList = ({
  contentContainerStyle,
  isLoading,
  facilities,
  currentPosition,
  search,
  onPressFacility
}: Props) => {
  const renderItem = useCallback(
    ({ item }: { item: components['schemas']['FacilityListItemResponse'] }) => {
      return (
        <FacilityListItem
          key={item._id}
          facility={item}
          {...(currentPosition !== undefined && {
            distance: calculateDistanceToFacility(
              currentPosition,
              item.coordinates as Coordinates
            )
          })}
          onPress={onPressFacility}
        />
      )
    },
    [currentPosition]
  )

  return (
    <View style={{ flex: 1 }}>
      <FlashList
        bounces={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={contentContainerStyle}
        data={
          currentPosition !== undefined
            ? sortFacilitiesByDistance(currentPosition, facilities)
            : facilities
        }
        renderItem={renderItem}
        ListEmptyComponent={
          isLoading ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <FacilityListItemSkeleton key={index} />
              ))}
            </>
          ) : (
            <NoResults
              messageTranslationKey="noFacilitiesFound"
              search={search}
            />
          )
        }
        estimatedItemSize={94}
      />
    </View>
  )
}
