// External
import { useTranslation } from 'react-i18next'
import { View, type ViewStyle } from 'react-native'
// Components
import { Input } from './Input'
import { Select } from './Select'
import { Text } from './Text'
// Constants
import { countryPrefixOptions } from '@/common/constants'
// Models
import type { Option } from '@/common/models'

interface Props {
  prefixValue: string
  value: string
  style?: ViewStyle
  onChangePhone: (value: string) => void
  onChangePrefix: (value: Option) => void
  errorMessage?: string
  labelTranslationKey?: string
}

export const PhoneNumberInput = ({
  prefixValue,
  value,
  style,
  onChangePhone,
  onChangePrefix,
  errorMessage,
  labelTranslationKey = 'yourMobileNumber'
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={style}>
      <Text variant="label">{t(labelTranslationKey)}</Text>

      <View
        style={{
          flexDirection: 'row',
          gap: 15
        }}
      >
        <Select
          style={{ width: 112 }}
          inputStyle={{
            fontFamily: 'PlusJakartaSans_700Bold',
            fontSize: 17
          }}
          value={prefixValue}
          searchable
          searchPlaceholder={t('searchACountry')}
          options={countryPrefixOptions}
          onSelect={(option) => {
            onChangePrefix(option)
          }}
        />

        <Input
          style={{ flex: 1 }}
          keyboardType="phone-pad"
          value={value}
          onChangeText={onChangePhone}
          errorMessage={errorMessage}
        />
      </View>
    </View>
  )
}
