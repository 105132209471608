import { type FacilityUserTypeSlug, type StowlogIcon } from '@/common/models'

export const userTypeIcons: Record<FacilityUserTypeSlug, StowlogIcon> = {
  passenger: 'passenger',
  visitor: 'visitor',
  contractor: 'contractor',
  'truck-driver': 'truck-driver',
  employee: 'employee',
  'wh-operator': 'warehouse',
  'wh-maintenance-operator': 'warehouse-mo',
  'fruit-trucker': 'fruit-truck-driver',
  provider: 'provider',
  'ship-service': 'ship-service',
  'port-user': 'visitor',
  'government-entity': 'employee',
  'container-terminal-truck-driver': 'truck-driver',
  'general-cargo-truck-driver': 'truck-driver'
}
