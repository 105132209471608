// External
import { Input } from '@rneui/themed'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  TouchableOpacity as RNTouchableOpacity,
  StyleSheet,
  View
} from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
// Components
import { Icon, Text } from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
// Models
import type { Asset } from '@/common/models'
// Utils
import { getAttachmentName } from '@/common/utils'

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

interface Props {
  attachments: Asset[]
  onAddAttachment?: (attachment: Asset) => void
  onRemoveAttachment?: (index: number) => void
  limit?: number
  name: string
  description?: string
  placeholder: string
  errorMessage?: string
  isRequired: boolean
}

export const CustomFieldAttachmentList = ({
  attachments,
  onAddAttachment,
  onRemoveAttachment,
  limit = 3,
  name,
  description,
  placeholder,
  errorMessage,
  isRequired
}: Props) => {
  const { t } = useTranslation()
  return (
    <>
      <Text variant="label" style={{ marginBottom: 2 }}>
        {isRequired ? name : t('optional', { label: name })}
      </Text>

      {description !== undefined && description.length > 0 && (
        <Text style={{ marginBottom: 4 }} variant="small">
          {description}
        </Text>
      )}

      <Wrapper
        {...(onAddAttachment !== undefined && {
          onPress: () => {
            if (attachments.length >= limit) {
              toast.error({
                data: {
                  messageTranslationKey: 'attachmentLimitReached'
                }
              })
              return
            }

            void SheetManager.show('upload-options', {
              payload: {
                onImagePickerAsset: (asset) => {
                  onAddAttachment(asset)
                  void SheetManager.hide('upload-options')
                },
                onDocumentPickerAsset: (asset) => {
                  onAddAttachment(asset)
                  void SheetManager.hide('upload-options')
                }
              }
            })
          }
        })}
      >
        <Input
          inputStyle={{
            fontFamily: 'PlusJakartaSans_500Medium'
          }}
          inputContainerStyle={{
            borderColor:
              errorMessage !== undefined ? colors.error : 'transparent'
          }}
          editable={false}
          placeholder={placeholder}
          rightIcon={<Icon name="add-file" size={20} />}
          errorMessage={errorMessage}
        />
      </Wrapper>

      {attachments.length > 0 && (
        <View style={{ gap: 10 }}>
          {attachments.map((attachment, index) => (
            <View key={index} style={styles.attachmentContainer}>
              <Icon name="file" size={20} />

              <Text style={{ flex: 1 }} numberOfLines={1} variant="small">
                {getAttachmentName({ attachment, index })}
              </Text>

              {onRemoveAttachment !== undefined && (
                <Icon
                  name="remove-file"
                  size={16}
                  onPress={() => {
                    onRemoveAttachment(index)
                  }}
                />
              )}
            </View>
          ))}
        </View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  attachmentContainer: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 7,
    borderColor: colors.inactive,
    borderWidth: 1,
    paddingHorizontal: 15,
    gap: 6
  }
})
