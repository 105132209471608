// External
import { type StackScreenProps } from '@react-navigation/stack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
// Components
import {
  ActionItem,
  Checkbox,
  ProgressBar,
  StepNavigationButtons,
  Text,
  TextAndLink
} from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { CreateAccountParams } from '@/auth/models'
import type { PreHomeStackParamList } from '@/common/models'
// Services
import { getEmailAvailable } from '@/auth/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { createAccount } from '@/auth/useCases'
// Utils
import { handleError, openTermsAndConditions } from '@/common/utils'
import type { ImagePickerAsset } from 'expo-image-picker'

type Props = StackScreenProps<PreHomeStackParamList, 'ReviewYourInformation'>

const ReviewYourInformation = ({ route }: Props) => {
  const [userData, setUserData] = useState<CreateAccountParams>(route.params)
  const [acceptsTerms, setAcceptsTerms] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { t } = useTranslation()

  const logIn = useAppStore((state) => state.logIn)

  return (
    <SafeArea withBackground>
      <View style={{ marginHorizontal: 25 }}>
        <ProgressBar style={{ marginTop: 11 }} value={1} />

        <Text variant="h2Bold" style={styles.header}>
          {t('reviewInformation')}
        </Text>

        <Text style={{ marginBottom: 42 }}>{t('confirmInformation')}</Text>

        <Text variant="smallBold" style={{ marginBottom: 20 }}>
          {t('accountDetails')}
        </Text>
      </View>

      <ScrollView
        contentContainerStyle={styles.contentContainer}
        bounces={false}
      >
        <ActionItem
          iconName="email"
          title={t('yourEmailAddress')}
          text={userData.email}
          onPress={() => {
            void SheetManager.show('edit-email', {
              payload: {
                onSubmit: async (email) => {
                  try {
                    const available = await getEmailAvailable(email)

                    if (available == null) {
                      toast.error({
                        data: {
                          titleTranslationKey: 'emailNotAvailableTitle',
                          messageTranslationKey: 'emailNotAvailableMessage'
                        }
                      })
                      return
                    }

                    setUserData((prev) => ({ ...prev, email }))
                    void SheetManager.hide('edit-email')
                  } catch (error) {
                    handleError(error)
                  }
                },
                initialValue: userData.email
              }
            })
          }}
        />

        <ActionItem
          iconName="user-name"
          title={t('yourName')}
          text={`${userData.firstName} ${userData.lastName}`}
          onPress={() => {
            void SheetManager.show('edit-name', {
              payload: {
                onSubmit: ({ firstName, lastName }) => {
                  setUserData((prev) => ({ ...prev, firstName, lastName }))
                  void SheetManager.hide('edit-name')
                },
                initialValues: {
                  firstName: userData.firstName,
                  lastName: userData.lastName
                }
              }
            })
          }}
        />

        <ActionItem
          iconName="password"
          title={t('password')}
          text="************"
          onPress={() => {
            void SheetManager.show('edit-password', {
              payload: {
                onSubmit: ({ password }) => {
                  setUserData((prev) => ({ ...prev, password }))
                  void SheetManager.hide('edit-password')
                }
              }
            })
          }}
        />

        <ActionItem
          iconName="phone-number"
          title={t('mobileNumber')}
          text={`+${userData.phoneNumber.prefix} ${userData.phoneNumber.phone}`}
          onPress={() => {
            void SheetManager.show('edit-number', {
              payload: {
                onSubmit: ({ prefix, phone }) => {
                  setUserData((prev) => ({
                    ...prev,
                    phoneNumber: { prefix, phone }
                  }))
                  void SheetManager.hide('edit-number')
                },
                initialValues: {
                  prefix: parseInt(userData.phoneNumber.prefix),
                  phone: userData.phoneNumber.phone
                }
              }
            })
          }}
        />

        <ActionItem
          iconName="profile-picture"
          title={t('yourProfilePhoto')}
          text={
            userData.profileImage !== undefined
              ? t('selected', {
                  number: 1
                })
              : t('noneSelected')
          }
          onPress={() => {
            void SheetManager.show('edit-profile-photo', {
              payload: {
                onSubmit: (photo) => {
                  setUserData((prev) => ({
                    ...prev,
                    profileImage: photo as ImagePickerAsset
                  }))
                  void SheetManager.hide('edit-profile-photo')
                },
                initialValue: userData.profileImage
              }
            })
          }}
        />

        <Text variant="smallBold" style={styles.termsAndConditionsText}>
          {t('termsAndConditions')}
        </Text>

        <View style={styles.termsAndConditions}>
          <Checkbox
            checked={acceptsTerms}
            onPress={() => {
              setAcceptsTerms(!acceptsTerms)
            }}
          />

          <TextAndLink
            text={t('byClickingOnSignup')}
            linkText={t('termsAndConditions')}
            linkOnPress={openTermsAndConditions}
          />
        </View>
      </ScrollView>

      <View style={{ margin: 25 }}>
        <StepNavigationButtons
          nextTitleTranslationKey="signUp"
          nextDisabled={!acceptsTerms || isSubmitting}
          nextOnPress={async () => {
            try {
              setIsSubmitting(true)
              const res = await createAccount(userData)

              toast.success({
                data: {
                  messageTranslationKey: res.messageTranslationKey
                }
              })

              logIn(res.data.user)
              setIsSubmitting(false)
            } catch (error) {
              setIsSubmitting(false)
              handleError(error)
            }
          }}
        />
      </View>
    </SafeArea>
  )
}

export default ReviewYourInformation

const styles = StyleSheet.create({
  header: {
    marginTop: 48,
    marginBottom: 8,
    textTransform: 'capitalize'
  },
  termsAndConditions: {
    flexDirection: 'row',
    gap: 16,
    marginBottom: 42
  },
  termsAndConditionsText: {
    marginBottom: 16,
    marginTop: 36
  },
  contentContainer: {
    paddingHorizontal: 25,
    gap: 20
  }
})
