import { client } from '@/common/config'
import type { Asset } from '@/common/models'
import { getAndroidFileType, getWebFile } from '@/common/utils'
import { Platform } from 'react-native'

interface Params {
  ptwRequestUUID: string
  attachmentUUID: string
  attachment: Asset
}

export const replacePermitAttachment = async ({
  ptwRequestUUID,
  attachmentUUID,
  ...rest
}: Params) => {
  const { uri } = rest.attachment

  let attachment: any

  const isDocumentPickerAsset = 'name' in rest.attachment
  if (Platform.OS === 'web') {
    attachment = await getWebFile({
      asset: rest.attachment,
      fallbackName: new Date().toDateString()
    })
  } else {
    attachment = {
      name: new Date().toDateString(),
      type:
        Platform.OS === 'android'
          ? getAndroidFileType(rest.attachment)
          : isDocumentPickerAsset
          ? rest.attachment.mimeType
          : // @ts-expect-error - attachment.type exists because it's not a DocumentPickerAsset
            rest.attachment.type,
      uri: Platform.OS === 'ios' ? uri.replace('file://', '') : uri
    }
  }

  const { error } = await client.POST(
    '/api/app/permit-to-work/attachments/{ptwRequestUUID}/replace/{attachmentUUID}',
    {
      params: {
        path: {
          ptwRequestUUID,
          attachmentUUID
        }
      },
      body: {
        attachment
      },
      bodySerializer: (body) => {
        const fd = new FormData()
        for (const name in body) {
          fd.append(name, body[name as keyof typeof body])
        }
        return fd
      }
    }
  )

  if (error !== undefined) {
    console.error('Error replacing permit attachment:', error)
    return await Promise.reject(error)
  }
}
