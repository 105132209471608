import { Input, Text } from '@/common/components'
import type { components } from '@/common/models'
import useAppStore from '@/common/stores/useAppStore'
import { formatDate } from '@/common/utils'
import { useTranslation } from 'react-i18next'

interface Props {
  extension: components['schemas']['PTWExtensionRequestRowItemResponse']
}

export const Extension = ({
  extension: { createdAt, extensionDate, observations }
}: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  return (
    <>
      <Text variant="baseBold">{t('extensionDetails')}</Text>

      <Text style={{ marginBottom: 24 }}>{t('theseAreTheDetails')}</Text>

      <Input
        disabled
        label={t('requestedOn')}
        value={formatDate(
          new Date(createdAt),
          'P, hh:mm a',
          currentFacility?.info.timezone
        )}
      />

      <Input
        disabled
        label={t('extensionDate')}
        value={formatDate(
          new Date(extensionDate),
          'P, hh:mm a',
          currentFacility?.info.timezone
        )}
      />

      {observations !== undefined && (
        <Input disabled label={t('comments')} value={observations} />
      )}
    </>
  )
}
