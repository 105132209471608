// External
import { addMinutes } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
// Services
import {
  createVisitRequest,
  uploadVisitRequestQuestionAttachment
} from '@/visit/services'

interface Params {
  hosts: string[]
  date: {
    day: number
    month: number
    year: number
  }
  checkIn: string
  checkOut: string
  visitQuestionAnswers: Array<{
    questionId: string
    answer: string
  }>
  timezone: string
  attachments: Array<{
    visitCheckinQuestionId: string
    attachment: ImagePickerAsset | DocumentPickerAsset
  }>
}

export const requestVisit = async ({
  date,
  checkIn,
  checkOut,
  timezone,
  attachments,
  ...rest
}: Params) => {
  try {
    const baseDate = zonedTimeToUtc(
      new Date(date.year, date.month - 1, date.day),
      timezone
    )
    const checkInDate = addMinutes(baseDate, parseInt(checkIn))
    const checkOutDate = addMinutes(baseDate, parseInt(checkOut))

    const { _id } = await createVisitRequest({
      date: checkInDate.toISOString(),
      checkIn: checkInDate.toISOString(),
      checkOut: checkOutDate.toISOString(),
      ...rest
    })

    const promises = attachments.map(async (attachment) => {
      await uploadVisitRequestQuestionAttachment({
        visitRequestId: _id,
        visitCheckinQuestionId: attachment.visitCheckinQuestionId,
        attachment: attachment.attachment
      })
    })

    await Promise.all(promises)
  } catch (error) {
    return await Promise.reject(error)
  }
}
