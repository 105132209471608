import { UploadOptions } from '@/common/components'
import { ActionSheetContainer } from '@/common/layouts'
import { type SheetProps } from 'react-native-actions-sheet'

export const UploadOptionsSheet = ({
  payload,
  sheetId
}: SheetProps<'upload-options'>) => {
  return (
    <ActionSheetContainer id={sheetId} isModal={false}>
      <UploadOptions
        onImagePickerAsset={(asset) => {
          void payload?.onImagePickerAsset(asset)
        }}
        {...(payload?.onDocumentPickerAsset !== undefined && {
          onDocumentPickerAsset: (asset) => {
            void payload?.onDocumentPickerAsset!(asset)
          }
        })}
      />
    </ActionSheetContainer>
  )
}
