// External
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Text } from '@/common/components'
// Models
import type { components } from '@/common/models'

interface Props {
  ppes: components['schemas']['PTWRequestDetailResponse']['ppeRequirements']
}

export const PermitPPEList = ({ ppes }: Props) => {
  const { t } = useTranslation()

  return (
    <View>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('ppeRequirements')}
      </Text>
      {ppes.map(({ _id, label }, i) => (
        <Text variant="small" key={_id}>
          {`• ${label}`}
        </Text>
      ))}
    </View>
  )
}
