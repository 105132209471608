// External
import { useTranslation } from 'react-i18next'
import { SheetManager, type SheetProps } from 'react-native-actions-sheet'
// Components
import { VerifyEmailForm } from '@/auth/components'
import { Text } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Services
import { validateEmailChange } from '@/auth/services'
import { getUser } from '@/common/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { toast } from '@/common/constants'
import { handleError } from '@/common/utils'

type Props = SheetProps<'change-email'>

export const ChangeEmail = ({ payload, sheetId }: Props) => {
  const { user, setUser } = useAppStore((state) => ({
    user: state.user,
    setUser: state.setUser
  }))
  const { t } = useTranslation()

  return (
    <ActionSheetContainer id={sheetId} isModal={false}>
      <Text style={{ marginBottom: 24 }}>
        {t('enterThe4DigitCode', { email: payload?.email })}
      </Text>

      <VerifyEmailForm
        email={payload?.email as string}
        onSubmit={async ({ verificationCode }) => {
          try {
            if (payload?.email !== undefined) {
              await validateEmailChange({
                email: payload.email,
                verificationCode
              })

              const updatedUser = await getUser()

              setUser({ ...user, ...updatedUser })

              toast.success({
                data: {
                  messageTranslationKey: 'emailChanged'
                }
              })
              void SheetManager.hide(sheetId)
            }
          } catch (error) {
            handleError(error)
          }
        }}
        sendAgainType="change"
        buttonType="submit"
      />
    </ActionSheetContainer>
  )
}
