import { Icon, Text } from '@/common/components'
import { colors } from '@/common/constants'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View, type ViewStyle } from 'react-native'

interface Props {
  style?: ViewStyle
  compact?: boolean
  cancelationReason: string
}

export const PermitCancelationCard = ({
  style,
  compact = false,
  cancelationReason
}: Props) => {
  const { t } = useTranslation()

  return (
    <View style={{ ...styles.genericTypeOfWork, ...style }}>
      <Icon name="alert" size={20} style={styles.icon} color={colors.error} />

      <View style={{ flex: 1 }}>
        <Text variant={compact ? 'smallBold' : 'baseBold'}>
          {t('cancelationReason')}
        </Text>

        <Text variant={compact ? 'small' : 'base'}>{cancelationReason}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    marginTop: 5
  },
  genericTypeOfWork: {
    borderRadius: 7,
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 1,
    flexDirection: 'row',
    gap: 8,
    borderColor: colors.lightGray10Percent
  }
})
