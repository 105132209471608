// External
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
// Components
import { Text } from '@/common/components'
// Models
import { PermitToWorkQuestionAnswers, type components } from '@/common/models'

interface Props {
  arrangements: components['schemas']['PTWRequestDetailResponse']['preArrangementQuestions']
}

export const PermitArrangementList = ({ arrangements }: Props) => {
  const { t } = useTranslation()

  return (
    <View>
      <Text style={{ marginBottom: 8 }} variant="label">
        {t('preArrangements')}
      </Text>
      {arrangements.map(({ question, answer }, i) => (
        <Text key={i} variant="small">
          {`• ${question} `}
          <Text variant="smallBold">
            {t(PermitToWorkQuestionAnswers[answer])}
          </Text>
        </Text>
      ))}
    </View>
  )
}
