// External
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SheetManager, type SheetProps } from 'react-native-actions-sheet'
import { object, string, type InferType } from 'yup'
// Constants
import { toast } from '@/common/constants'
// Components
import { Button, Input, Text } from '@/common/components'
// Layouts
import { ActionSheetContainer } from '@/common/layouts'
// Services
import { forgotPassword } from '@/auth/services'
// Utils
import { handleError } from '@/common/utils'

type Props = SheetProps<'forgot-password'>

export const ForgotPassword = ({ sheetId, payload }: Props) => {
  const { t } = useTranslation()

  const validationSchema = object({
    email: string().email().required(t('provideEmail'))
  })

  type FormValues = InferType<typeof validationSchema>

  const onSubmit = async ({ email }: FormValues) => {
    try {
      await forgotPassword(email)
      toast.success({
        data: {
          titleTranslationKey: 'forgotPasswordSuccessTitle',
          messageTranslationKey: 'forgotPasswordSuccessMessage'
        }
      })
      void SheetManager.hide(sheetId)
    } catch (error) {
      handleError(error)
    }
  }

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: payload?.email ?? ''
    }
  })

  return (
    <ActionSheetContainer id={sheetId}>
      <Text
        style={{
          textAlign: 'center',
          marginBottom: 30
        }}
        variant="baseBold"
      >
        {t('resetPassword')}
      </Text>

      <Text style={{ marginBottom: 30 }}>{t('resetPasswordInstructions')}</Text>

      <Controller
        control={control}
        name="email"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            placeholder={t('yourEmail')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            errorMessage={errors.email?.message}
          />
        )}
      />

      <Button
        style={{ marginTop: 18 }}
        title={t('restorePassword')}
        onPress={handleSubmit(onSubmit)}
        disabled={isSubmitting}
        loading={isSubmitting}
      />
    </ActionSheetContainer>
  )
}
