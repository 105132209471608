import { client } from '@/common/config'

export const requestPermitClosure = async (ptwRequestUUID: string) => {
  const { error } = await client.POST(
    '/api/app/permit-to-work/requests/closures/{ptwRequestUUID}/submit',
    {
      params: {
        path: {
          ptwRequestUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error requesting permit closure:', error)
    return await Promise.reject(error)
  }
}
