import { AttachmentList, Link, Text } from '@/common/components'
import { colors, toast } from '@/common/constants'
import {
  PTWExtensionRequestStatus,
  PTWRequestStatus,
  type components
} from '@/common/models'
import { handleError } from '@/common/utils'
import {
  deletePermitRequestAttachment,
  replacePermitAttachment,
  uploadPermitRequestAttachments
} from '@/permitToWork/services'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View, useWindowDimensions } from 'react-native'
import { SheetManager } from 'react-native-actions-sheet'
import { Divider } from 'react-native-paper'
import RenderHtml, { defaultSystemFonts } from 'react-native-render-html'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface Props
  extends Pick<
    components['schemas']['PTWRequestDetailResponse'],
    'uuid' | 'status' | 'requestAttachments' | 'requestedAttachmentsRichText'
  > {
  issuersAttachments: Array<components['schemas']['PTWAttachmentDetailDto']>
  attachments: Array<components['schemas']['PTWAttachmentDetailDto']>
  refetch: () => void
  hasActiveExtension: boolean
  extensions: Array<components['schemas']['PTWExtensionRequestRowItemResponse']>
}

export const PermitAttachments = ({
  uuid,
  status,
  issuersAttachments = [],
  requestAttachments,
  requestedAttachmentsRichText,
  attachments = [],
  refetch,
  hasActiveExtension,
  extensions
}: Props) => {
  const [isUploading, setIsUploading] = useState(false)
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const { left, right } = useSafeAreaInsets()
  const allowActions =
    status === PTWRequestStatus.PENDING &&
    !hasActiveExtension &&
    extensions.filter(
      (extension) => extension.status === PTWExtensionRequestStatus.APPROVED
    ).length === 0

  if (!requestAttachments && issuersAttachments.length === 0) {
    return null
  }

  if (!requestAttachments && issuersAttachments.length > 0) {
    return (
      <>
        <Text variant="label">{t('issuersAttachments')}</Text>

        <AttachmentList
          attachments={
            issuersAttachments.map(({ uuid, attachment }) => ({
              uuid,
              extension: attachment.extension,
              name: attachment.name,
              mimeType: attachment.mimeType,
              size: attachment.size,
              url: attachment.url
            })) as any
          }
        />
      </>
    )
  }

  return (
    <>
      {issuersAttachments.length > 0 && (
        <>
          <View>
            <Text variant="label">{t('issuersAttachments')}</Text>

            <AttachmentList
              attachments={
                issuersAttachments.map(({ uuid, attachment }) => ({
                  uuid,
                  extension: attachment.extension,
                  name: attachment.name,
                  mimeType: attachment.mimeType,
                  size: attachment.size,
                  url: attachment.url
                })) as any
              }
            />
          </View>

          <Divider style={{ marginVertical: 20 }} />
        </>
      )}

      <View style={{ gap: 20 }}>
        <View>
          <Text variant="label">{t('requestedAttachments')}</Text>

          {requestedAttachmentsRichText !== undefined &&
          requestedAttachmentsRichText.length > 0 ? (
            <RenderHtml
              source={{ html: requestedAttachmentsRichText }}
              systemFonts={[
                ...defaultSystemFonts,
                'PlusJakartaSans_400Regular',
                'PlusJakartaSans_700Bold',
                'PlusJakartaSans_400Regular_Italic'
              ]}
              contentWidth={width - 25 * 2 - left - right}
              baseStyle={{
                color: colors.text,
                fontFamily: 'PlusJakartaSans_400Regular',
                fontSize: 15,
                lineHeight: 21,
                letterSpacing: 0.35
              }}
              tagsStyles={{
                em: {
                  fontFamily: 'PlusJakartaSans_400Regular_Italic'
                },
                h1: {
                  fontSize: 23,
                  margin: 0,
                  marginBottom: 4
                },
                h2: {
                  fontSize: 20,
                  margin: 0,
                  marginBottom: 4
                },
                h3: {
                  fontSize: 17,
                  margin: 0,
                  marginBottom: 4
                },
                // https://github.com/meliorence/react-native-render-html/issues/592#issuecomment-1547510349
                p: {
                  marginVertical: 0
                },
                strong: {
                  fontFamily: 'PlusJakartaSans_700Bold'
                }
              }}
            />
          ) : (
            <Text variant="small">{t('pleaseUploadAnyFiles')}</Text>
          )}
        </View>

        <View>
          <Text variant="label">{t('attachments')}</Text>

          <AttachmentList
            style={{ marginBottom: 8 }}
            attachments={
              attachments.map(({ uuid, attachment }) => ({
                uuid,
                extension: attachment.extension,
                name: attachment.name,
                mimeType: attachment.mimeType,
                size: attachment.size,
                url: attachment.url
              })) as any
            }
            {...(allowActions && {
              options: [
                {
                  label: t('delete'),
                  onPress: async (attachment) => {
                    try {
                      setIsUploading(true)
                      await deletePermitRequestAttachment({
                        ptwRequestUUID: uuid,
                        attachmentUUID: attachment.uuid
                      })
                      refetch()
                      toast.success({
                        data: {
                          messageTranslationKey: 'attachmentDeleted'
                        }
                      })
                    } catch (error) {
                      handleError(error)
                    } finally {
                      setIsUploading(false)
                    }
                  }
                },
                {
                  label: t('replace'),
                  onPress: async (attachment) => {
                    void SheetManager.show('upload-options', {
                      payload: {
                        onImagePickerAsset: async (asset) => {
                          try {
                            setIsUploading(true)
                            await SheetManager.hide('upload-options')
                            await replacePermitAttachment({
                              ptwRequestUUID: uuid,
                              attachmentUUID: attachment.uuid,
                              attachment: asset
                            })
                            refetch()
                            toast.success({
                              data: {
                                messageTranslationKey: 'attachmentReplaced'
                              }
                            })
                          } catch (error) {
                            handleError(error)
                          } finally {
                            setIsUploading(false)
                          }
                        },
                        onDocumentPickerAsset: async (asset) => {
                          try {
                            setIsUploading(true)
                            await SheetManager.hide('upload-options')
                            await replacePermitAttachment({
                              ptwRequestUUID: uuid,
                              attachmentUUID: attachment.uuid,
                              attachment: asset
                            })
                            refetch()
                            toast.success({
                              data: {
                                messageTranslationKey: 'attachmentReplaced'
                              }
                            })
                          } catch (error) {
                            handleError(error)
                          } finally {
                            setIsUploading(false)
                          }
                        }
                      }
                    })
                  }
                }
              ]
            })}
          />

          {allowActions && (
            <Link
              style={{
                alignSelf: 'center'
              }}
              onPress={() => {
                void SheetManager.show('upload-options', {
                  payload: {
                    onImagePickerAsset: async (asset) => {
                      try {
                        setIsUploading(true)
                        await SheetManager.hide('upload-options')
                        await uploadPermitRequestAttachments({
                          uuid,
                          attachments: [asset]
                        })
                        refetch()
                        toast.success({
                          data: {
                            messageTranslationKey: 'attachmentUploaded'
                          }
                        })
                      } catch (error) {
                        handleError(error)
                      } finally {
                        setIsUploading(false)
                      }
                    },
                    onDocumentPickerAsset: async (asset) => {
                      try {
                        setIsUploading(true)
                        await SheetManager.hide('upload-options')
                        await uploadPermitRequestAttachments({
                          uuid,
                          attachments: [asset]
                        })
                        refetch()
                        toast.success({
                          data: {
                            messageTranslationKey: 'attachmentUploaded'
                          }
                        })
                      } catch (error) {
                        handleError(error)
                      } finally {
                        setIsUploading(false)
                      }
                    }
                  }
                })
              }}
              color={colors.rajah}
              icon={{
                name: 'add-file'
              }}
              disabled={isUploading}
            >
              {t('uploadAttachments')}
            </Link>
          )}
        </View>
      </View>
    </>
  )
}
