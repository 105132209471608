// External
import { createStackNavigator } from '@react-navigation/stack'
// Models
import type { VisitStackParamList } from '@/visit/models'
// Views
import EditPreVisitQuestions from '@/visit/views/EditPreVisitQuestions'
import RequestAVisit from '@/visit/views/RequestAVisit'
import VisitDetail from '@/visit/views/VisitDetail'
import Visits from '@/visit/views/VisitRequests'

export const Stack = createStackNavigator<VisitStackParamList>()

const VisitsStack = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="VisitRequests" component={Visits} />

      <Stack.Screen name="RequestAVisit" component={RequestAVisit} />

      <Stack.Screen name="VisitDetail" component={VisitDetail} />

      <Stack.Screen
        name="EditPreVisitQuestions"
        component={EditPreVisitQuestions}
      />
    </Stack.Navigator>
  )
}

export default VisitsStack
