// External
import type { PTWStackParamList } from '@/permitToWork/models'
import type { StackScreenProps } from '@react-navigation/stack'
import { Divider } from '@rneui/themed'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
// Components
import {
  FacilityTimezone,
  Input,
  Link,
  Text,
  TopBar
} from '@/common/components'
import {
  Extension,
  PermitArea,
  PermitArrangementList,
  PermitAttachments,
  PermitCancelationCard,
  PermitComments,
  PermitDetailSkeleton,
  PermitLocationList,
  PermitPersonInCharge,
  PermitPPEList,
  PermitRejectionCard,
  PermitRequester,
  PermitSignatures,
  PermitStaffOnSiteList,
  PermitStatus,
  PermitTypeOfWorks
} from '@/permitToWork/components'
import { useState } from 'react'
// Constants
import { colors, toast } from '@/common/constants'
// Hooks
import useRefetchOnFocus from '@/common/hooks/useRefetchOnFocus'
// Layouts
import { SafeArea } from '@/common/layouts'
// Services
import {
  getPermitAttachments,
  requestPermitClosure
} from '@/permitToWork/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { callSOS, formatDate, handleError } from '@/common/utils'
import { getExtendedPermitStatus } from '@/permitToWork/utils'
// Use cases
import { PTWRequestStatus } from '@/common/models'
import { getUserModules } from '@/home/useCases'
import { getPermitDetail } from '@/permitToWork/useCases'

type Props = StackScreenProps<PTWStackParamList, 'PermitDetail'>

const PermitDetail = ({ navigation, route }: Props) => {
  const { user, currentUserType, currentFacility } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType,
    currentFacility: state.currentFacility
  }))
  const { showModal } = usePortalStore((state) => ({
    showModal: state.showModal
  }))
  const [requestingClosure, setRequestingClosure] = useState(false)
  const { t } = useTranslation()
  const { top, right, left, bottom } = useSafeAreaInsets()
  const { height, width } = useWindowDimensions()

  const { uuid, seq } = route.params

  const { data: modulesData, refetch: refetchModules } = useQuery({
    queryKey: [
      'modules',
      user?._id,
      currentUserType?._id,
      currentFacility?._id
    ],
    queryFn: getUserModules
  })

  const {
    data: detailData,
    refetch: refetchDetail,
    isRefetching: isRefetchingDetail,
    isLoading: isLoadingDetail
  } = useQuery({
    queryKey: ['permitDetail', uuid],
    queryFn: async () => await getPermitDetail(uuid)
  })

  const {
    data: attachmentsData,
    refetch: refetchAttachments,
    isRefetching: isRefetchingAttachments
  } = useQuery({
    queryKey: ['permitAttachments', uuid],
    queryFn: async () => await getPermitAttachments(uuid)
  })

  useRefetchOnFocus([refetchModules, refetchDetail, refetchAttachments])

  if (detailData === undefined || isLoadingDetail) {
    return (
      <SafeArea>
        <TopBar
          style={styles.topBar}
          title={t('requestID', { id: seq })}
          leftIcon={{
            name: 'close',
            onPress: navigation.goBack
          }}
        />

        <PermitDetailSkeleton />
      </SafeArea>
    )
  }

  const { detail, signatures, comments } = detailData
  const status = getExtendedPermitStatus(detail)

  return (
    <View
      style={{
        flex: 1,
        paddingTop: top,
        paddingRight: right,
        paddingLeft: left
      }}
    >
      <TopBar
        style={styles.topBar}
        title={t('requestID', { id: seq })}
        leftIcon={{
          name: 'close',
          onPress: navigation.goBack
        }}
        rightIcons={[
          {
            name: 'sos',
            onPress: () => {
              // eslint-disable-next-line
              void callSOS(currentFacility!)
            },
            color: 'error'
          }
        ]}
      />

      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 25,
          paddingBottom: bottom + height * 0.25
        }}
        refreshControl={
          <RefreshControl
            onRefresh={() => {
              void refetchDetail()
              void refetchAttachments()
            }}
            refreshing={isRefetchingDetail || isRefetchingAttachments}
            progressViewOffset={-15}
          />
        }
      >
        <View
          {...(width > 768 && {
            style: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }
          })}
        >
          <PermitPersonInCharge
            personInChargeContractor={detail.personInChargeContractor}
            personInChargePhoneNumber={detail.personInChargePhoneNumber}
          />

          {detail.status === PTWRequestStatus.APPROVED && (
            <Link
              {...(width > 768 && {
                style: {
                  alignSelf: 'center'
                }
              })}
              onPress={async () => {
                try {
                  setRequestingClosure(true)
                  await requestPermitClosure(detailData.detail.uuid)
                  toast.success({
                    data: {
                      titleTranslationKey: 'closureRequestSubmitted',
                      messageTranslationKey:
                        'yourRequestToCloseThePermitHasBeenSubmitted'
                    }
                  })
                } catch (error) {
                  handleError(error)
                } finally {
                  setRequestingClosure(false)
                }
              }}
              color={colors.rajah}
              icon={{
                name: 'clipboard-check',
                size: 16
              }}
              disabled={requestingClosure}
            >
              {t('requestClosure')}
            </Link>
          )}
        </View>

        <Divider style={{ marginVertical: 20 }} />

        <PermitRequester
          facilityMemberSubmitter={detail.facilityMemberSubmitter}
        />

        <View style={styles.dateAndStatus}>
          <View style={styles.createdAt}>
            <Text variant="small">
              {t('requestCreatedAt', {
                date: formatDate(
                  new Date(detail.createdAt),
                  'Pp',
                  currentFacility?.info.timezone
                )
              })}
            </Text>
          </View>

          <PermitStatus compact status={status} />
        </View>

        <FacilityTimezone style={{ marginTop: 12 }} />

        {detail.rejectionReason !== undefined &&
          detail.status === PTWRequestStatus.REJECTED && (
            <PermitRejectionCard
              style={{ marginTop: 24 }}
              compact
              rejectionReason={detail.rejectionReason}
            />
          )}

        {detail.cancelationReason !== undefined &&
          detail.status === PTWRequestStatus.CANCELED && (
            <PermitCancelationCard
              style={{ marginTop: 24 }}
              compact
              cancelationReason={detail.cancelationReason}
            />
          )}

        <Divider style={{ marginVertical: 20 }} />

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 12
          }}
        >
          <Input
            style={{ flex: 1 }}
            disabled
            label={t('startDate')}
            value={formatDate(
              new Date(detail.startDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />

          <Input
            style={{ flex: 1 }}
            disabled
            label={t('endDate')}
            value={formatDate(
              new Date(detail.endDate),
              'Pp',
              currentFacility?.info.timezone
            )}
          />
        </View>

        {detail.status === PTWRequestStatus.APPROVED && (
          <>
            <Link
              style={{ alignSelf: 'center' }}
              onPress={() => {
                if (detailData.pendingExtension !== undefined) {
                  showModal({
                    content: (
                      <Extension extension={detailData.pendingExtension} />
                    )
                  })
                  return
                }

                navigation.navigate('RequestAnExtension', {
                  _id: detail._id,
                  endDate: detail.endDate,
                  genericTypeOfWork: detail.genericTypeOfWork
                })
              }}
              color={colors.rajah}
              icon={
                detailData.pendingExtension !== undefined
                  ? {
                      name: 'show'
                    }
                  : {
                      name: 'calendar-plus',
                      size: 16
                    }
              }
            >
              {t(
                detailData.pendingExtension !== undefined
                  ? 'viewPendingExtension'
                  : 'requestAnExtension'
              )}
            </Link>

            <Divider style={{ marginVertical: 20 }} />
          </>
        )}

        <Input disabled label={t('description')} value={detail.description} />

        <Divider style={{ marginVertical: 20 }} />

        <PermitStaffOnSiteList
          permitUUID={detail.uuid}
          status={detail.status}
          staffOnSite={detail.staffOnSite}
          refetch={refetchDetail}
        />

        <Divider style={{ marginVertical: 20 }} />

        {(modulesData?.PTWConfig?.hasAreaSelectionEnabled ?? false) && (
          <>
            <PermitArea area={detail.area} />

            <Divider style={{ marginVertical: 20 }} />
          </>
        )}

        <PermitLocationList
          locations={detail.locations}
          otherLocation={detail.otherLocation}
        />

        <Divider style={{ marginVertical: 20 }} />

        {(modulesData?.PTWConfig?.hasPreArrangementsEnabled ?? false) && (
          <>
            <PermitArrangementList
              arrangements={detail.preArrangementQuestions}
            />

            <Divider style={{ marginVertical: 20 }} />
          </>
        )}

        <PermitPPEList ppes={detail.ppeRequirements} />

        <Divider style={{ marginVertical: 20 }} />

        <PermitTypeOfWorks
          genericTypeOfWorks={Boolean(detail.genericTypeOfWork)}
          typeOfworks={detail.typeOfWorks}
        />

        <Divider style={{ marginVertical: 20 }} />

        <PermitAttachments
          uuid={detail.uuid}
          status={detail.status}
          attachments={attachmentsData?.attachments ?? []}
          issuersAttachments={attachmentsData?.issuersAttachments ?? []}
          requestAttachments={detail.requestAttachments}
          requestedAttachmentsRichText={detail.requestedAttachmentsRichText}
          refetch={refetchAttachments}
          hasActiveExtension={detailData.detail.hasActiveExtension}
          extensions={detailData.extensions ?? []}
        />

        {comments.length > 0 && (
          <>
            <Divider style={{ marginVertical: 20 }} />

            <PermitComments comments={comments} />
          </>
        )}
      </ScrollView>

      <PermitSignatures permit={detailData.detail} signatures={signatures} />
    </View>
  )
}

export default PermitDetail

const styles = StyleSheet.create({
  topBar: {
    marginBottom: 27,
    marginHorizontal: 25
  },
  createdAt: {
    paddingHorizontal: 10,
    paddingVertical: 6.5,
    backgroundColor: '#FAFAFA',
    borderRadius: 7,
    marginEnd: 'auto'
  },
  dateAndStatus: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 8
  }
})
