// Models
import { type ToastProps } from '@/common/models'

export const toast = {
  success: ({ data }: Pick<ToastProps, 'data'>) => {
    const id = toastNotifications.show('', {
      type: 'success',
      data
    })

    return id
  },
  error: ({ data, duration }: Pick<ToastProps, 'data' | 'duration'>) => {
    const id = toastNotifications.show('', {
      type: 'error',
      data,
      duration
    })

    return id
  }
}
