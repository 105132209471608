import { getVisitDetail, getVisitRequestDetail } from '@/visit/services'

interface Params {
  visitRequestId: string
  visitRequestUUID: string
  isApproved: boolean
}

export const getVisit = async ({
  visitRequestId,
  visitRequestUUID,
  isApproved
}: Params) => {
  try {
    if (isApproved) {
      return await getVisitDetail(visitRequestId)
    }

    return await getVisitRequestDetail(visitRequestUUID)
  } catch (error) {
    return await Promise.reject(error)
  }
}
