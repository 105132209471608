import { toast } from '@/common/constants'
import type { components } from '@/common/models'
import * as Linking from 'expo-linking'

export const callSOS = async (
  facility: components['schemas']['FacilityDetailResponse']
) => {
  try {
    const dialerURL = `tel:00${facility.info.sosNumber.prefix}${facility.info.sosNumber.phone}`
    await Linking.openURL(dialerURL)
  } catch {
    toast.error({
      data: {
        titleTranslationKey: 'sosNumberNotAvailable',
        messageTranslationKey: 'sosNumberNotAvailableMessage'
      }
    })
  }
}
