// External
import { Skeleton } from '@rneui/themed'
import * as Linking from 'expo-linking'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
  type ViewStyle
} from 'react-native'
import { Menu } from 'react-native-paper'
// Models
import type { components } from '@/common/models'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Constants
import { colors } from '@/common/constants'

interface Props {
  style?: ViewStyle
  attachments: Array<components['schemas']['AttachmentModel']>
  isLoading?: boolean
  options?: Array<{
    label: string
    onPress: (
      attachment: components['schemas']['AttachmentModel']
    ) => void | Promise<void>
  }>
}

export const AttachmentList = ({
  style,
  attachments,
  isLoading,
  options = []
}: Props) => {
  const [visible, setVisible] = useState(attachments.map(() => false))
  const { t } = useTranslation()
  const optionsWithPreview = [
    {
      label: t('preview'),
      onPress: (attachment: components['schemas']['AttachmentModel']) => {
        if (Platform.OS === 'web') {
          window.open(attachment.url, '_blank')
          return
        }

        void Linking.openURL(attachment.url)
      }
    },
    ...options
  ]

  const renderMenu = (
    attachment: components['schemas']['AttachmentModel'],
    index: number
  ) => {
    if (Platform.OS === 'web') {
      return (
        <View style={{ flexShrink: 1 }}>
          <Icon name="dots-menu" size={20} />

          <select
            value=""
            style={{
              height: 20,
              width: 20,
              opacity: 0,
              position: 'absolute'
            }}
            onChange={(e) => {
              const selectedOption = optionsWithPreview.find(
                (option) => option.label === e.target.value
              )

              if (selectedOption !== undefined) {
                void selectedOption.onPress(attachment)
              }
            }}
          >
            <option value="" disabled>
              {t('attachmentOptions')}
            </option>

            {optionsWithPreview.map((option, index) => (
              <option key={index} value={option.label}>
                {option.label}
              </option>
            ))}
          </select>
        </View>
      )
    }

    return (
      <Menu
        visible={visible[index] as boolean}
        onDismiss={() => {
          const visibleCopy = [...visible]
          visibleCopy[index] = false
          setVisible(visibleCopy)
        }}
        anchor={
          <Icon
            name="dots-menu"
            onPress={() => {
              const visibleCopy = [...visible]
              visibleCopy[index] = true
              setVisible(visibleCopy)
            }}
            size={20}
          />
        }
        anchorPosition="bottom"
        contentStyle={styles.content}
      >
        <ScrollView style={styles.scroll} bounces={false}>
          {optionsWithPreview.map((option, index) => (
            <TouchableOpacity
              style={styles.option}
              key={index}
              onPress={() => {
                void option.onPress(attachment)
                setVisible(attachments.map(() => false))
              }}
            >
              <Text variant="small">{option.label}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Menu>
    )
  }

  return (
    <View style={[styles.container, style]}>
      {attachments.length === 0 && !(isLoading ?? false) && (
        <Text color={colors.placeholder} variant="small">
          {t('noAttachmentsHaveBeenUploaded')}
        </Text>
      )}

      {attachments.map((attachment, index) =>
        isLoading ?? false ? (
          <Skeleton key={index} width="100%" animation="wave" height={40} />
        ) : (
          <View key={index} style={styles.attachmentContainer}>
            <Icon name="file" size={20} />

            <Text style={{ flex: 1 }} numberOfLines={1} variant="small">
              {`${attachment.name}.${attachment.extension}`}
            </Text>

            {renderMenu(attachment, index)}
          </View>
        )
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    gap: 10
  },
  attachmentContainer: {
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 7,
    borderColor: colors.inactive,
    paddingHorizontal: 15,
    borderWidth: 1,
    gap: 6
  },
  content: {
    paddingVertical: 0,
    marginTop: 5,
    backgroundColor: 'transparent'
  },
  scroll: {
    backgroundColor: colors.white,
    borderRadius: 7
  },
  option: {
    paddingHorizontal: 10,
    paddingVertical: 5
  }
})
