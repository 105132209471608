import { client } from '@/common/config'
import { PTWAttachmentUploadedByType } from '@/common/models'

export const getPermitAttachments = async (ptwRequestUUID: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/attachments/{ptwRequestUUID}',
    {
      params: {
        path: {
          ptwRequestUUID
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error getting permit attachments:', error)
    return await Promise.reject(error)
  }

  const issuersAttachments = data.data.filter(
    (attachment) =>
      attachment.uploadedByType === PTWAttachmentUploadedByType.ISSUER
  )

  const attachments = data.data.filter(
    (attachment) =>
      attachment.uploadedByType === PTWAttachmentUploadedByType.CONTRACTOR
  )

  return {
    issuersAttachments,
    attachments
  }
}
