// External
import { yupResolver } from '@hookform/resolvers/yup'
import { Input as RNEInput } from '@rneui/themed'
import { isSameDay } from 'date-fns'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  Platform,
  TouchableOpacity as RNTouchableOpacity,
  ScrollView,
  StyleSheet,
  View
} from 'react-native'
import { TouchableOpacity as GHTouchableOpacity } from 'react-native-gesture-handler'
import { type InferType } from 'yup'
// Components
import {
  Button,
  DateInput,
  Icon,
  InfiniteSelect,
  Input,
  Select,
  Text
} from '@/common/components'
// Constants
import { colors, toast } from '@/common/constants'
import { requestVisitFormSchema } from '@/visit/constants'
// Models
import type { Option, components } from '@/common/models'
import { VisitCheckinQuestionTypes } from '@/common/models'
// Services
import { getHosts } from '@/visit/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Use cases
import { requestVisit } from '@/visit/useCases'
// Utils
import { handleError } from '@/common/utils'
import { getCheckInTimeValues, getCheckOutTimeValues } from '@/visit/utils'
import { SheetManager } from 'react-native-actions-sheet'

const now = new Date()

const Wrapper =
  Platform.OS === 'android' ? RNTouchableOpacity : GHTouchableOpacity

type FormValues = InferType<typeof requestVisitFormSchema>

interface Props {
  checkinQuestions: Array<
    components['schemas']['GetVisitCheckinQuestionKioskResponse']
  >
  onSubmitSuccess: () => void
}

export const RequestVisitForm = ({
  checkinQuestions,
  onSubmitSuccess
}: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(requestVisitFormSchema),
    defaultValues: {
      date: {
        day: now.getDate(),
        month: now.getMonth() + 1,
        year: now.getFullYear()
      },
      checkIn: '',
      checkOut: '',
      hosts: [],
      visitQuestionAnswers: checkinQuestions.map((question) => ({
        questionId: question._id,
        optional: question.optional,
        answer: '',
        type: question.type,
        attachment: undefined
      }))
    }
  })

  const { fields } = useFieldArray<FormValues>({
    control,
    name: 'visitQuestionAnswers'
  })

  const date = watch('date')
  const checkIn = watch('checkIn')
  const checkOut = watch('checkOut')

  const onSubmit = async ({ visitQuestionAnswers, ...rest }: FormValues) => {
    try {
      const answersToSubmit = visitQuestionAnswers
        .filter(
          (answer) =>
            answer.answer !== '' &&
            answer.answer !== undefined &&
            answer.type !== VisitCheckinQuestionTypes.ATTACHMENT
        )
        .map((answer) => ({
          questionId: answer.questionId,
          answer: answer.answer as string
        }))
      const attachmentsToUpload = visitQuestionAnswers
        .filter(
          (answer) =>
            answer.type === VisitCheckinQuestionTypes.ATTACHMENT &&
            answer.attachment !== undefined
        )
        .map((answer) => ({
          visitCheckinQuestionId: answer.questionId,
          attachment: answer.attachment as any
        }))

      await requestVisit({
        ...rest,
        visitQuestionAnswers: answersToSubmit,
        timezone: currentFacility?.info.timezone as string,
        attachments: attachmentsToUpload
      })
      onSubmitSuccess()
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.contentContainer}
        bounces={false}
      >
        <Text variant="h2Bold" style={styles.title}>
          {t('requestAVisit')}
        </Text>

        <Text style={{ marginBottom: 25 }}>{t('theVisitMustBeApproved')}</Text>

        <Controller
          control={control}
          name="date"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateInput
              label={t('date')}
              value={new Date(value.year, value.month - 1, value.day)}
              minDate={new Date().toDateString()}
              onDayPress={(data) => {
                onChange({ day: data.day, month: data.month, year: data.year })
                onBlur()
              }}
              placeholder={t('selectADate')}
              errorMessage={errors.date?.message}
            />
          )}
        />

        <View style={styles.time}>
          <View style={{ flex: 1 }}>
            <Controller
              control={control}
              name="checkIn"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  label={t('checkinTime')}
                  value={value}
                  options={getCheckInTimeValues(
                    isSameDay(
                      new Date(date.year, date.month - 1, date.day),
                      new Date()
                    )
                  )}
                  onSelect={(option) => {
                    if (parseInt(checkOut) - parseInt(option.value) > 1440) {
                      toast.error({
                        data: {
                          messageTranslationKey: 'visitLastMoreThan24HoursError'
                        }
                      })
                      onBlur()
                      return
                    }

                    if (parseInt(option.value) === parseInt(checkOut)) {
                      toast.error({
                        data: {
                          messageTranslationKey: 'checkInCheckOutEqualError'
                        }
                      })
                      onBlur()
                      return
                    }

                    if (parseInt(option.value) > parseInt(checkOut)) {
                      toast.error({
                        data: {
                          messageTranslationKey: 'checkInAfterCheckOutError'
                        }
                      })
                      onBlur()
                      return
                    }

                    onChange(option.value)
                    onBlur()
                  }}
                  placeholder={t('selectATime')}
                  errorMessage={errors.checkIn?.message}
                />
              )}
            />
          </View>

          <View style={{ flex: 1 }}>
            <Controller
              control={control}
              name="checkOut"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  label={t('checkoutTime')}
                  disabled={checkIn === ''}
                  value={value}
                  options={getCheckOutTimeValues(checkIn)}
                  onSelect={(option) => {
                    onChange(option.value)
                    onBlur()
                  }}
                  placeholder={t('selectATime')}
                  errorMessage={errors.checkOut?.message}
                />
              )}
            />
          </View>
        </View>

        <Controller
          control={control}
          name="hosts"
          render={({ field: { onChange, onBlur } }) => (
            <InfiniteSelect<
              components['schemas']['FacilityMemberSearchResponse']
            >
              searchable
              label={t('hosts')}
              placeholder={t('selectHosts')}
              searchPlaceholder={t('searchHosts')}
              onMultipleSelect={(options) => {
                onChange(options.map((option) => option.value))
                onBlur()
              }}
              errorMessage={errors.hosts?.message}
              queryKey={['hosts', currentFacility?._id]}
              queryFn={async ({ page, search }) =>
                await getHosts({
                  facilityId: currentFacility?._id as string,
                  page,
                  search
                })
              }
              getOptions={(data) =>
                data.map((host) => ({
                  label: host.fullName,
                  value: host.uuid
                }))
              }
              noResultsTranslationKey="noHostsFound"
            />
          )}
        />

        {checkinQuestions.length > 0 && (
          <>
            <Text
              variant="baseBold"
              style={{ marginTop: 24, marginBottom: 12 }}
            >
              {t('preVisitQuestions')}
            </Text>

            {fields.map((field, index) => {
              const checkinQuestion = checkinQuestions[index]

              if (checkinQuestion === undefined) {
                return null
              }

              return (
                <Controller
                  key={field.id}
                  control={control}
                  name={`visitQuestionAnswers.${index}`}
                  render={({ field: { onChange, onBlur, value } }) => {
                    const commonProps = {
                      label: value.optional
                        ? t('optional', {
                            label: checkinQuestion.translation.question
                          })
                        : checkinQuestion.translation.question,
                      value: value.answer,
                      placeholder: checkinQuestion.translation.placeholder,
                      errorMessage:
                        errors.visitQuestionAnswers?.[index]?.answer?.message ??
                        errors.visitQuestionAnswers?.[index]?.attachment
                          ?.message
                    }

                    if (
                      checkinQuestion.type ===
                      VisitCheckinQuestionTypes.DROPDOWN
                    ) {
                      return (
                        <Select
                          {...commonProps}
                          options={
                            checkinQuestion.translation.answers.map(
                              (answer) => ({
                                label: answer,
                                value: answer
                              })
                            ) as Option[]
                          }
                          onSelect={(option) => {
                            onChange({
                              ...value,
                              answer: option.value
                            })
                            onBlur()
                          }}
                        />
                      )
                    }

                    if (
                      checkinQuestion.type ===
                      VisitCheckinQuestionTypes.ATTACHMENT
                    ) {
                      let displayedFileName: string | undefined

                      if (value.attachment !== undefined) {
                        if (
                          'name' in value.attachment &&
                          typeof value.attachment.name === 'string'
                        ) {
                          displayedFileName = value.attachment.name
                        } else if (
                          'fileName' in value.attachment &&
                          typeof value.attachment.fileName === 'string'
                        ) {
                          displayedFileName = value.attachment.fileName
                        } else if (
                          'uri' in value.attachment &&
                          typeof value.attachment.uri === 'string'
                        ) {
                          displayedFileName = `${new Date().toDateString()}.${value.attachment.uri
                            .split('.')
                            .pop()!}`
                        }
                      }

                      return (
                        <>
                          <Text variant="label">{commonProps.label}</Text>

                          <Wrapper
                            onPress={() => {
                              void SheetManager.show('upload-options', {
                                payload: {
                                  onImagePickerAsset: (asset) => {
                                    onChange({
                                      ...value,
                                      attachment: asset
                                    })
                                    void SheetManager.hide('upload-options')
                                  },
                                  onDocumentPickerAsset: (asset) => {
                                    onChange({
                                      ...value,
                                      attachment: asset
                                    })
                                    void SheetManager.hide('upload-options')
                                  }
                                }
                              })
                            }}
                          >
                            <RNEInput
                              placeholder={commonProps.placeholder}
                              errorMessage={commonProps.errorMessage}
                              inputContainerStyle={{
                                borderColor:
                                  commonProps.errorMessage !== undefined
                                    ? colors.error
                                    : 'transparent'
                              }}
                              editable={false}
                              rightIcon={<Icon name="add-file" size={20} />}
                              value={displayedFileName}
                            />
                          </Wrapper>
                        </>
                      )
                    }

                    return (
                      <Input
                        {...commonProps}
                        onChangeText={(text) => {
                          onChange({
                            ...value,
                            answer: text
                          })
                        }}
                        onBlur={onBlur}
                      />
                    )
                  }}
                />
              )
            })}
          </>
        )}
      </ScrollView>

      <Button
        disabled={isSubmitting}
        loading={isSubmitting}
        style={styles.submitButton}
        title={t('requestVisit')}
        onPress={handleSubmit(onSubmit)}
      />
    </>
  )
}

const styles = StyleSheet.create({
  title: {
    marginTop: 36,
    marginBottom: 8
  },
  contentContainer: {
    paddingHorizontal: 25,
    paddingBottom: 25
  },
  time: {
    flexDirection: 'row',
    gap: 12
  },
  label: {
    fontSize: 13,
    fontFamily: 'PlusJakartaSans_500Medium',
    marginBottom: 4
  },
  submitButton: {
    marginTop: 'auto',
    marginHorizontal: 25
  }
})
