// External
import type { DocumentPickerAsset } from 'expo-document-picker'
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'
// Config
import { client } from '@/common/config'
// Utils
import { getAndroidFileType, getWebFile } from '@/common/utils'

interface Params {
  visitRequestId: string
  visitCheckinQuestionId: string
  attachment: ImagePickerAsset | DocumentPickerAsset
}

export const uploadVisitRequestQuestionAttachment = async ({
  visitCheckinQuestionId,
  visitRequestId,
  ...rest
}: Params) => {
  try {
    let attachment: any

    if (Platform.OS === 'web') {
      attachment = await getWebFile({
        asset: rest.attachment,
        fallbackName: new Date().toDateString()
      })
    } else {
      const isDocumentPickerAsset = 'name' in rest.attachment

      attachment = {
        name: isDocumentPickerAsset
          ? rest.attachment.name.split('.')[0]
          : rest.attachment.fileName ?? new Date().toDateString(),
        type:
          Platform.OS === 'android'
            ? getAndroidFileType(attachment)
            : isDocumentPickerAsset
            ? rest.attachment.mimeType
            : rest.attachment.type,
        uri:
          Platform.OS === 'ios'
            ? rest.attachment.uri.replace('file://', '')
            : rest.attachment.uri
      }
    }

    const { error } = await client.POST(
      '/api/app/visit-requests/{visitRequestId}/{visitCheckinQuestionId}',
      {
        body: {
          attachment
        },
        bodySerializer: (body) => {
          const fd = new FormData()
          for (const name in body) {
            fd.append(name, body[name as keyof typeof body])
          }
          return fd
        },
        params: {
          path: {
            visitRequestId,
            visitCheckinQuestionId
          }
        }
      }
    )

    if (error !== undefined) {
      console.error('Error uploading question attachment:', error)
      return await Promise.reject(error)
    }
  } catch (error) {
    console.error('Error uploading question attachment:', error)
    return await Promise.reject(error)
  }
}
