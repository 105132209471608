import { client } from '@/common/config'

export const getVisitDetail = async (visitRequestId: string) => {
  const { data, error } = await client.GET('/api/app/visit', {
    params: {
      query: {
        visitRequestId
      }
    }
  })

  if (error !== undefined) {
    console.error('Error getting visit detail:', error)
    return await Promise.reject(error)
  }

  return data.data
}
