// External
import type { ImagePickerAsset } from 'expo-image-picker'
import { Platform } from 'react-native'
// Config
import { client } from '@/common/config'
// Utils
import { getAndroidFileType, getWebFile } from '@/common/utils'

interface Params {
  profileImage: ImagePickerAsset
}

export const updateProfileImage = async ({ profileImage }: Params) => {
  try {
    const { uri } = profileImage

    let image: any

    if (Platform.OS === 'web') {
      image = await getWebFile({
        asset: profileImage,
        fallbackName: 'Profile photo'
      })
    } else {
      image = {
        name: 'Profile photo',
        type:
          Platform.OS === 'android'
            ? getAndroidFileType(profileImage)
            : profileImage.type,
        uri: Platform.OS === 'ios' ? uri.replace('file://', '') : uri
      }
    }

    const { data, error } = await client.POST('/api/auth/app/profile-image', {
      body: {
        image
      },
      bodySerializer: (body) => {
        const fd = new FormData()
        for (const name in body) {
          fd.append(name, body[name as keyof typeof body])
        }
        return fd
      }
    })

    if (error !== undefined) {
      console.error('Error updating profile image:', error)
      return await Promise.reject(error)
    }

    return data.data.profileImage
  } catch (error) {
    console.error('Error updating profile image:', error)
    return await Promise.reject(error)
  }
}
