import { client } from '@/common/config'

interface Params {
  visitUUID: string
  visitQuestionAnswers: Array<{
    questionId: string
    answer: string
  }>
}

export const updateVisit = async ({
  visitUUID,
  visitQuestionAnswers
}: Params) => {
  const { data, error } = await client.PATCH('/api/app/visit/{visitUUID}', {
    body: {
      visitQuestionAnswers
    },
    params: {
      path: {
        visitUUID
      }
    }
  })

  if (error !== undefined) {
    console.error('Error updating visit:', error)
    return await Promise.reject(error)
  }

  return data.data
}
