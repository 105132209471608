import { StyleSheet, View, type ViewStyle } from 'react-native'
import { QuestionAnswer } from './QuestionAnswer'
import { Text } from './Text'

interface Props {
  style?: ViewStyle
  question: string
  onSelectAnswer: (answer: string) => void
  value?: string
  answers: Array<{
    label: string
    value: string
    isSelected?: boolean
  }>
}

export const Question = ({
  style,
  question,
  onSelectAnswer,
  value,
  answers
}: Props) => {
  return (
    <View style={style}>
      <Text style={styles.question} variant="mediumBold">
        {question}
      </Text>

      <View style={{ gap: 17 }}>
        {answers.map((answer) => (
          <QuestionAnswer
            label={answer.label}
            value={answer.value}
            onSelect={onSelectAnswer}
            isSelected={value === answer.value}
          />
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  question: {
    marginBottom: 38,
    textAlign: 'center'
  }
})
