// External
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useWindowDimensions } from 'react-native'
// Components
import { DrawerContent } from '@/common/components'
// Hooks
import useIsRTL from '@/common/hooks/useIsRTL'
// Models
import type { FindFacilityDrawerParamList } from '@/findFacility/models'
// Views
import Settings from '@/common/views/Settings'
import Filters from '@/findFacility/views/Filters'
import FindFacility from '@/findFacility/views/FindFacility'
// Utils
import { getDrawerScreenOptions } from '@/common/utils'

const Drawer = createDrawerNavigator<FindFacilityDrawerParamList>()

const FindFacilityDrawer = () => {
  const isRTL = useIsRTL()
  const { width } = useWindowDimensions()

  return (
    <Drawer.Navigator
      initialRouteName="FindFacility"
      drawerContent={DrawerContent}
      screenOptions={{
        ...getDrawerScreenOptions(width),
        drawerPosition: isRTL ? 'right' : 'left'
      }}
    >
      <Drawer.Screen name="FindFacility" component={FindFacility} />

      <Drawer.Screen
        name="Filters"
        component={Filters}
        options={{ swipeEnabled: false }}
      />

      <Drawer.Screen
        name="Settings"
        component={Settings}
        options={{ swipeEnabled: false }}
      />
    </Drawer.Navigator>
  )
}
export default FindFacilityDrawer
