// External
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type InferType, object, string } from 'yup'
// Components
import { Button, Input, Text } from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Services
import { cancelVisitRequest } from '@/visit/services'
// Stores
import usePortalStore from '@/common/stores/usePortalStore'
// Utils
import { handleError } from '@/common/utils'

const validationSchema = object({
  reason: string()
})

type FormValues = InferType<typeof validationSchema>

interface Props {
  visitRequestUUID: string
  onCancel: () => void
}

export const CancelVisitRequest = ({ visitRequestUUID, onCancel }: Props) => {
  const { hideModal } = usePortalStore((state) => ({
    hideModal: state.hideModal
  }))
  const { t } = useTranslation()

  const onSubmit = async ({ reason }: FormValues) => {
    try {
      await cancelVisitRequest({
        visitRequestUUID,
        reason: reason as string
      })
      toast.success({
        data: {
          messageTranslationKey: 'visitCancelled'
        }
      })
      onCancel()
      hideModal()
    } catch (error) {
      handleError(error)
    }
  }

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema)
  })

  return (
    <>
      <Controller
        control={control}
        name="reason"
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            style={{ marginBottom: 8 }}
            placeholder={t('cancelationReason')}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(onSubmit)}
            onBlur={onBlur}
            value={value}
            returnKeyType="done"
            renderErrorMessage={false}
            numberOfLines={3}
          />
        )}
      />

      <Text variant="small">{t('providingAReasonForCancelation')}</Text>

      <Button
        style={{ marginTop: 48 }}
        disabled={isSubmitting}
        loading={isSubmitting}
        title={t('cancelVisit')}
        onPress={handleSubmit(onSubmit)}
      />
    </>
  )
}
