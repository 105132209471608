// External
import { Linking, TouchableOpacity, View, type ViewStyle } from 'react-native'
// Components
import { Avatar, Text } from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Stores
import useAppStore from '@/common/stores/useAppStore'

interface Props {
  style?: ViewStyle
}

export const Facility = ({ style }: Props) => {
  const currentFacility = useAppStore((state) => state.currentFacility)

  if (currentFacility === undefined) {
    return null
  }

  const country = currentFacility.info.country.name
  const address = `${currentFacility.info.address}, ${country}`

  const openInGoogleMaps = async () => {
    try {
      const query = currentFacility.name + ', ' + address
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        query
      )}`

      await Linking.openURL(mapsUrl)
    } catch {
      toast.error({
        data: {
          titleTranslationKey: 'error',
          messageTranslationKey: 'couldntOpenInGoogleMaps'
        }
      })
    }
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          gap: 20,
          marginBottom: 30,
          alignItems: 'center'
        },
        style
      ]}
    >
      <Avatar
        source={{ uri: currentFacility.onboarding?.avatarUrl }}
        size={95}
      />

      <View style={{ flex: 1 }}>
        <Text variant="largeBold" numberOfLines={2}>
          {currentFacility?.name}
        </Text>

        <TouchableOpacity onPress={openInGoogleMaps}>
          <Text numberOfLines={2}>{address}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
