import { client } from '@/common/config'

export const getPermitClosure = async (ptwRequestUUID: string) => {
  const { data, error } = await client.GET(
    '/api/app/permit-to-work/requests/closures/{ptwRequestUUID}',
    {
      params: {
        path: {
          ptwRequestUUID
        }
      }
    }
  )
  if (error !== undefined) {
    // console.error('Error getting permit closure:', error)
    return undefined
  }

  return data.data
}
