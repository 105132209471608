// External
import type { DrawerScreenProps } from '@react-navigation/drawer'
import type { CompositeScreenProps } from '@react-navigation/native'
import type { StackScreenProps } from '@react-navigation/stack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
// Components
import { BackgroundIcon, Button, Text, TopBar } from '@/common/components'
// Constants
import { toast } from '@/common/constants'
// Layouts
import { SafeArea } from '@/common/layouts'
// Models
import type { HomeDrawerParamList } from '@/home/models'
import type { InductionStackParamList } from '@/safetyInduction/models'
// Services
import { generateInductionReport } from '@/safetyInduction/services'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { formatDate, handleError } from '@/common/utils'

type Props = CompositeScreenProps<
  StackScreenProps<InductionStackParamList, 'SuccessfulInduction'>,
  DrawerScreenProps<HomeDrawerParamList>
>

const SuccesfulInduction = ({ navigation, route }: Props) => {
  const { user, currentUserType } = useAppStore((state) => ({
    user: state.user,
    currentUserType: state.currentUserType
  }))
  const [sending, setSending] = useState(false)
  const { t } = useTranslation()
  const {
    expirationDate,
    alreadyCompleted,
    uuid: safetyInductionUUID
  } = route.params

  return (
    <SafeArea style={styles.container}>
      {alreadyCompleted && (
        <TopBar
          title={t('induction')}
          leftIcon={{
            name: 'back',
            onPress: navigation.goBack
          }}
        />
      )}

      <View style={styles.middleContent}>
        <Text variant="largeBold" style={styles.inductionCompleted}>
          {t('inductionCompleted', {
            fullName: user?.fullName,
            userType: t(`userTypePlurals.${currentUserType?.slug as string}`)
          })}
        </Text>

        <BackgroundIcon iconName="trophy" />

        {typeof expirationDate === 'string' && (
          <>
            <Text>{t('inductionIsValidUntil')}</Text>

            <Text variant="mediumBold">
              {formatDate(new Date(expirationDate), 'P')}
            </Text>
          </>
        )}
      </View>

      <Button
        style={{ marginBottom: 25 }}
        title={t('sendDetailsToEmail')}
        loading={sending}
        disabled={sending}
        onPress={async () => {
          try {
            if (!(user?.isEmailVerified ?? false)) {
              toast.error({
                data: {
                  messageTranslationKey: 'emailNotVerifiedReportError'
                },
                duration: 5000
              })
              if (!alreadyCompleted) {
                navigation.navigate('Home')
              }
              return
            }

            setSending(true)
            await generateInductionReport({
              safetyInductionUUID: String(safetyInductionUUID)
            })
            setSending(false)
            toast.success({
              data: {
                messageTranslationKey: 'reportSent'
              }
            })

            if (!alreadyCompleted) {
              navigation.navigate('Home')
            }
          } catch (error) {
            setSending(false)
            handleError(error)
            if (!alreadyCompleted) {
              navigation.navigate('Home')
            }
          }
        }}
      />
    </SafeArea>
  )
}

export default SuccesfulInduction

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 25
  },
  middleContent: {
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingBottom: 42
  },
  inductionCompleted: {
    marginBottom: 42,
    textAlign: 'center'
  }
})
