// External
import type { ReactNode } from 'react'
import { StyleSheet, TouchableOpacity, type ViewStyle } from 'react-native'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Models
import type { StowlogIcon } from '@/common/models'

interface Props {
  style?: ViewStyle
  numberOfLines?: number
  children: ReactNode
  onPress: () => void
  color?: string
  icon?: {
    name: StowlogIcon
    size?: number
  }
  disabled?: boolean
}

export const Link = ({
  style,
  numberOfLines,
  children,
  onPress,
  color,
  icon,
  disabled = false
}: Props) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.container,
        {
          opacity: disabled ? 0.33 : 1
        },
        style
      ]}
      disabled={disabled}
    >
      {icon !== undefined && (
        <Icon name={icon.name} size={icon.size} color={color} />
      )}

      <Text variant="link" numberOfLines={numberOfLines} color={color}>
        {children}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8
  }
})
