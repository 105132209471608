// External
import { useNavigation, type NavigationProp } from '@react-navigation/native'
import { Divider } from '@rneui/themed'
import { useTranslation } from 'react-i18next'
import { StyleSheet, TouchableOpacity, View } from 'react-native'
// Components
import { Icon, Text } from '@/common/components'
// Constants
import { colors, shadows } from '@/common/constants'
import { visitStatusColors, visitStatusIcons } from '@/visit/constants'
// Models
import { VisitRequestStatus, type components } from '@/common/models'
import type { VisitStackParamList } from '@/visit/models'
// Stores
import useAppStore from '@/common/stores/useAppStore'
// Utils
import { formatDate } from '@/common/utils'

interface Props {
  visit: components['schemas']['VisitRequestPaginatedItem']
}

export const VisitRequestCard = ({ visit }: Props) => {
  const { currentFacility } = useAppStore((state) => ({
    currentFacility: state.currentFacility
  }))
  const { t } = useTranslation()
  const navigation = useNavigation<NavigationProp<VisitStackParamList>>()

  const { status, hosts, createdAt } = visit
  const hostNames = hosts
    .map((host) => `${host.firstName} ${host.lastName}`)
    .join(', ')

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        navigation.navigate('VisitDetail', {
          visitRequestId: visit._id,
          visitRequestUUID: visit.uuid,
          isApproved: visit.status === VisitRequestStatus.APPROVED
        })
      }}
    >
      <Text style={{ marginBottom: 8 }} variant="small" numberOfLines={1}>
        {`${t('hosts')}:`}
        <Text variant="smallBold">{` ${hostNames}`}</Text>
      </Text>

      <View style={styles.status}>
        <View
          style={[
            styles.iconContainer,
            { backgroundColor: visitStatusColors[status].iconBackground }
          ]}
        >
          <Icon
            name={visitStatusIcons[status]}
            color={visitStatusColors[status].icon}
            size={24}
          />
        </View>

        <View
          style={[
            styles.statusTextContainer,
            { backgroundColor: visitStatusColors[status].background }
          ]}
        >
          <Text color={visitStatusColors[status].text} variant="smallBold">
            {t(`visitCardStatus.${status}`)}
          </Text>
        </View>
      </View>

      <Divider style={styles.divider} />

      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text variant="small">{t('requestedOn')}</Text>

          <View style={styles.date}>
            <Text variant="smallBold">
              {formatDate(
                new Date(createdAt),
                'P',
                currentFacility?.info.timezone
              )}
            </Text>

            <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
              {formatDate(
                new Date(createdAt),
                'p',
                currentFacility?.info.timezone
              )}
            </Text>
          </View>
        </View>

        {visit.checkIn !== undefined && (
          <View style={{ flex: 1 }}>
            <Text variant="small">{t('visitDate')}</Text>

            <View style={styles.date}>
              <Text variant="smallBold">
                {formatDate(
                  new Date(visit.checkIn),
                  'P',
                  currentFacility?.info.timezone
                )}
              </Text>

              <Text style={{ marginBottom: 2.4 }} variant="extraSmall">
                {formatDate(
                  new Date(visit.checkIn),
                  'p',
                  currentFacility?.info.timezone
                )}
              </Text>
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    backgroundColor: colors.white,
    ...shadows.default,
    borderRadius: 10,
    height: 176
  },
  cancel: {
    position: 'absolute',
    top: 12,
    end: 12
  },
  status: {
    flexDirection: 'row',
    gap: 10
  },
  iconContainer: {
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusTextContainer: {
    paddingHorizontal: 15,
    height: 32,
    justifyContent: 'center',
    borderRadius: 10
  },
  divider: {
    marginTop: 20,
    marginBottom: 10
  },
  date: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 4
  }
})
