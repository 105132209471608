import type { Asset } from '@/common/models'

interface Params {
  asset: Asset
  fallbackName: string
}

export const getWebFile = async ({ asset, fallbackName }: Params) => {
  try {
    const { uri } = asset
    const fileName =
      'name' in asset ? asset.name : asset.fileName ?? fallbackName
    const blob = await (await fetch(uri)).blob()
    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (error) {
    console.error(error)
    return await Promise.reject(error)
  }
}
