// External
import { type ViewStyle, TouchableOpacity } from 'react-native'
// Components
import { Icon } from './Icon'
import { Text } from './Text'
// Models
import type { StowlogIcon } from '@/common/models'

interface Props {
  style?: ViewStyle
  color?: string
  iconName: StowlogIcon
  onPress: () => void
  text: string
}

export const DrawerContentItem = ({
  style,
  color,
  iconName,
  text,
  onPress
}: Props) => {
  return (
    <TouchableOpacity style={style} onPress={onPress}>
      <Icon name={iconName} size={20} color={color} />

      <Text
        variant="mediumBold"
        style={{ textTransform: 'capitalize' }}
        color={color}
      >
        {text}
      </Text>
    </TouchableOpacity>
  )
}
