import { client } from '@/common/config'

export const signPermit = async (uuid: string) => {
  const { error } = await client.POST(
    '/api/app/permit-to-work/requests/signatures/{uuid}/sign',
    {
      params: {
        path: {
          uuid
        }
      }
    }
  )

  if (error !== undefined) {
    console.error('Error signing permit:', error)
    return await Promise.reject(error)
  }
}
